import Grid from '@mui/material/Grid';
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {useMemo, useState} from "react";
import {useSalesRegions} from "../../hooks/useSalesRegions";
import {REP_TYPES} from "../../constants";
import {useSalesTerritories} from "../../hooks/useSalesTerritories";
import {useBusinessUnitCodes} from "../../hooks/useBusinessUnitCodes";
import MySelect from "../../components/MySelect";

const activatedOptions = [
    {id: '', name: '-- CLEAR --'},
    {id: true, name: 'Yes'},
    {id: false, name: 'No'},
];

const RepsFilter = ({filter, filterChangeFunctions, setFilter}) => {

    console.log('Rendering RepsFilter');

    // const [repTypeOptions, setRepTypeOptions] = useState([]);
    const [salesRegionOptions, setSalesRegionOptions] = useState([]);
    const [salesTerritoryOptions, setSalesTerritoryOptions] = useState([]);

    const [currentUser, _updateCurrentUser] = useCurrentUser(
        false,
    );
    const businessUnitCodes = useBusinessUnitCodes();

    const repTypeOptions = useMemo(() => {
        let options = [];
        if (currentUser && currentUser.repType) {
            options = REP_TYPES[currentUser.repType.id].visibleRepTypeIds.map((id) => {
                return {id, name: REP_TYPES[id].name};
            });
        }
        if (options.length > 0) {
            return [{id: '', name: '-- CLEAR --'}, ...options];
        } else {
            return options;
        }
    }, [currentUser.repType.id]);

    useSalesRegions(
        filter.businessUnitCode,
        currentUser.id,
        [1, 2].includes(currentUser.repType.id),
        (data) => {
            // Sort by repsCount desc
            data.sort((a, b) => b.repsCount - a.repsCount);

            // Then change name to name (repsCount)
            const updatedData = data.map(item => ({
                ...item,
                name: `${item.name} (${item.repsCount} ${item.repsCount > 1 ? 'users' : 'user'})`
            }));

            let options = [{id: '', name: '-- CLEAR --'}, ...updatedData];
            setSalesRegionOptions(options);
        }
    );

    useSalesTerritories(
        filter.businessUnitCode,
        filter.salesRegion,
        [1, 2, 3].includes(currentUser.repType.id),
        (data) => {
            if (data.length > 0) {
                let options = [{id: '', name: '-- CLEAR --'}, ...data];
                setSalesTerritoryOptions(options);
            } else {
                setSalesTerritoryOptions(data);
            }
            // setSalesTerritoryOptions(data);
        }
    );

    return (
        <Grid container spacing={1}>
            <Grid item xs={2.4}>
                <MySelect label="Activated" options={activatedOptions} value={filter.activated}
                          onChange={filterChangeFunctions.activated}/>
            </Grid>
            {currentUser.repType.id === 1 &&
                <Grid item xs={2.4}>
                    <MySelect label="Business Unit" options={businessUnitCodes} value={filter.businessUnitCode}
                              onChange={(value) => {
                                  filterChangeFunctions.businessUnitCode(value);
                              }}/>
                </Grid>
            }
            {[1, 2].includes(currentUser.repType.id) &&
                <Grid item xs={2.4}>
                    <MySelect
                        label="Sales Region"
                        options={salesRegionOptions}
                        value={filter.salesRegion}
                        autoSelect={true}
                        onChange={(value) => {
                            filterChangeFunctions.salesRegion(value);
                        }}
                    />
                </Grid>
            }
            {[1, 2, 3].includes(currentUser.repType.id) &&
                <Grid item xs={2.4}>
                    <MySelect label="Sales Territory" options={salesTerritoryOptions} value={filter.salesTerritory}
                              onChange={filterChangeFunctions.salesTerritory}/>
                </Grid>
            }
            {[1, 2, 3].includes(currentUser.repType.id) &&
                <Grid item xs={2.4}>
                    <MySelect label="User Type" options={repTypeOptions} value={filter.repTypeId}
                              onChange={filterChangeFunctions.repTypeId}/>
                </Grid>
            }
        </Grid>
    );
};

export default RepsFilter;