import { Box, Button } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import PageHeader from '../../components/PageHeader';
import MyBooleanMark from '../../components/MyBooleanMark';
import { useEvents } from '../../hooks/useEvents';
import { useEventTypes } from '../../hooks/useEventTypes';
import { useBookabilityLevels } from '../../hooks/useBookabilityLevels';
import { useSpecsVisibilities } from '../../hooks/useSpecsVisibilities';
import { toPercentage } from '../../util/NumberUtil';
import routes from '../../routes';
import { REP_TYPES } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../../util/AuthUtil';
import RepModal from '../reps/RepModal';
import EventModal from './EventModal';

const defaultColumnWidths = {
    address: 357,
    eventType: 147,
    productType: 164,
    indoorOutdoor: 245,
    shiptoName: 234,
    shipto: 146,
    currencyCode: 198,
    languageCode: 179,
    golfPro: 248,
    onsiteClubBuilding: 220,
    shareLink: 567,
    fitterCount: 168,
    businessUnitCode: 193,
    bookabilityLevel: 228,
    bookedPercentage: 195,
    eventTypeName: 209,
    onsiteBuildStation: 224,
    fittingsCount: 177,
    fittingDurationOpt: 232,
    specsVisibility: 198,
    id: 73,
    scheduledStart: 201,
    scheduledEnd: 216,
    locationName: 272,
    active: 120,
    completedPercentage: 188,
};
const EventsTable = () => {
    console.log('Rendering EventsTable');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });

    const navigate = useNavigate();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (
            !routes
                .find((route) => route.key === 'events')
                .visibleRepTypes.includes(currentUser.repType.id)
        ) {
            navigate(REP_TYPES[currentUser.repType.id].defaultRoute);
        }
    }, [currentUser, navigate]);

    const [eventColumnWidths, setEventColumnWidths] = useLocalStorage('eventColumnWidths', {});
    const [eventDetail, setEventDetail] = useState(null);
    const repModelRef = useRef();
    const onShowEventDetail = (event) => {
        setEventDetail(event);
        repModelRef.current.handleOpen();
    };
    // const eventTypes = useEventTypes();
    // const bookabilityLevels = useBookabilityLevels();
    // const specsVisibilities = useSpecsVisibilities();

    const columns = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: 'ID',
                width: eventColumnWidths.id ?? defaultColumnWidths.id,
            },
            {
                field: 'scheduledStart',
                headerName: 'START TIME',
                width: eventColumnWidths.scheduledStart ?? defaultColumnWidths.scheduledStart,
                renderCell: (params) => {
                    if (params.value) {
                        return new Date(params.value).toLocaleString();
                    } else {
                        return 'Unknown';
                    }
                },
            },
            {
                field: 'scheduledEnd',
                headerName: 'END TIME',
                width: eventColumnWidths.scheduledEnd ?? defaultColumnWidths.scheduledEnd,
                renderCell: (params) => {
                    if (params.value) {
                        return new Date(params.value).toLocaleString();
                    } else {
                        return 'Unknown';
                    }
                },
            },
            {
                field: 'locationName',
                headerName: 'LOCATION',
                width: eventColumnWidths.locationName ?? defaultColumnWidths.locationName,
                renderCell: (params) => {
                    if (params.row.facility) {
                        return params.row.facility.name;
                    } else if (params.row.location) {
                        return params.row.location.name;
                    } else {
                        return 'Unknown';
                    }
                },
            },
            {
                field: 'address',
                headerName: 'ADDRESS',
                width: eventColumnWidths.address ?? defaultColumnWidths.address,
                renderCell: (params) => {
                    if (params.row.facility) {
                        let facility = params.row.facility;
                        return [
                            facility.address1,
                            facility.address2,
                            facility.city,
                            facility.state,
                            facility.postalCode,
                        ]
                            .filter(Boolean)
                            .join(', ');
                    } else if (params.row.location) {
                        let location = params.row.location;
                        return [
                            location.address1,
                            location.address2,
                            location.city,
                            location.state,
                            location.postalCode,
                        ]
                            .filter(Boolean)
                            .join(', ');
                    } else {
                        return 'Unknown';
                    }
                },
            },
            {
                field: 'eventType',
                headerName: 'EVENT TYPE',
                width: eventColumnWidths.eventType ?? defaultColumnWidths.eventType,
                renderCell: (params) => {
                    const { eventType } = params.row;
                    let eventTypeName = eventType?.name;
                    return eventTypeName ?? '';
                },
            },
            {
                field: 'productType',
                headerName: 'PRODUCT TYPE',
                width: eventColumnWidths.productType ?? defaultColumnWidths.productType,
                renderCell: (params) => {
                    const { eventProductType } = params.row;
                    let eventProductTypeName = eventProductType?.name;
                    return eventProductTypeName ?? '';
                },
            },
            {
                field: 'indoorOutdoor',
                headerName: 'INDOOR / OUTDOOR',
                width: eventColumnWidths.indoorOutdoor ?? defaultColumnWidths.indoorOutdoor,
                renderCell: (params) => {
                    const { indoor } = params.row;
                    let indoorOutdoor = indoor === null ? null : indoor ? 'Indoor' : 'Outdoor';
                    return indoorOutdoor ?? '';
                },
            },
            {
                field: 'shipto',
                headerName: 'SHIP TO',
                width: eventColumnWidths.shipto ?? defaultColumnWidths.shipto,
                renderCell: (params) => {
                    let shipto = params.row.shipto;
                    return shipto ? shipto.shiptoId : 'Unknown';
                },
            },
            {
                field: 'shiptoName',
                headerName: 'SHIP TO NAME',
                width: eventColumnWidths.shiptoName ?? defaultColumnWidths.shiptoName,
                renderCell: (params) => {
                    let shipto = params.row.shipto;
                    return shipto ? shipto.name : 'Unknown';
                },
            },
            {
                field: 'golfPro',
                headerName: 'GOLF PRO',
                width: eventColumnWidths.golfPro ?? defaultColumnWidths.golfPro,
                renderCell: (params) => {
                    // const {eventAdmins} = params.row;
                    // let golfPro;
                    // if (eventAdmins) {
                    //     golfPro = eventAdmins.find(admin => admin.isPro);
                    // }
                    // return golfPro ? [golfPro.firstName, golfPro.lastName].filter(Boolean).join(' ') + ' (' + golfPro.email + ')' : 'None';
                    const { golfProFirstName, golfProLastName, golfProEmail } = params.row;
                    let email = golfProEmail ? ` (${golfProEmail})` : '';
                    return [golfProFirstName, golfProLastName].filter(Boolean).join(' ') + email;
                },
            },
            {
                field: 'onsiteBuildStation',
                width:
                    eventColumnWidths.onsiteBuildStation ?? defaultColumnWidths.onsiteBuildStation,
                headerName: 'BUILD STATION',
                renderCell: (params) => {
                    const { onsiteBuildStation } = params.row;
                    if (!onsiteBuildStation) {
                        return '';
                    }
                    return onsiteBuildStation?.name || '';
                },
            },
            {
                field: 'shareLink',
                headerName: 'SHARE LINK',
                width: eventColumnWidths.shareLink ?? defaultColumnWidths.shareLink,
            },
            {
                field: 'fitterCount',
                headerName: 'FITTER COUNT',
                width: eventColumnWidths.fitterCount ?? defaultColumnWidths.fitterCount,
            },
            {
                field: 'businessUnitCode',
                headerName: 'BUSINESS UNIT',
                width: eventColumnWidths.businessUnitCode ?? defaultColumnWidths.businessUnitCode,
            },
            {
                field: 'active',
                headerName: 'ACTIVE',
                width: eventColumnWidths.active ?? defaultColumnWidths.active,
                renderCell: (params) => <MyBooleanMark value={params.value} />,
            },
            {
                field: 'languageCode',
                headerName: 'LANGUAGE',
                width: eventColumnWidths.languageCode ?? defaultColumnWidths.languageCode,
            },
            {
                field: 'currencyCode',
                headerName: 'CURRENCY CODE',
                width: eventColumnWidths.currencyCode ?? defaultColumnWidths.currencyCode,
            },
            {
                field: 'bookabilityLevel',
                headerName: 'BOOKABILITY LEVEL',
                width: eventColumnWidths.bookabilityLevel ?? defaultColumnWidths.bookabilityLevel,
                renderCell: (params) => {
                    return params.value ? params.value.bookabilityLevelName : 'Unknown';
                },
            },
            {
                field: 'specsVisibility',
                headerName: 'SPECS VISIBILITY',
                width: eventColumnWidths.specsVisibility ?? defaultColumnWidths.specsVisibility,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'Unknown';
                },
            },
            {
                field: 'fittingDurationOpt',
                headerName: 'FITTING DURATION',
                width:
                    eventColumnWidths.fittingDurationOpt ?? defaultColumnWidths.fittingDurationOpt,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'Unknown';
                },
            },
            {
                field: 'fittingsCount',
                headerName: 'FITTINGS COUNT',
                width: eventColumnWidths.fittingsCount ?? defaultColumnWidths.fittingsCount,
            },
            {
                field: 'bookedPercentage',
                headerName: 'BOOKED PERCENTAGE',
                width: eventColumnWidths.bookedPercentage ?? defaultColumnWidths.bookedPercentage,
                renderCell: (params) => {
                    return toPercentage(params.value);
                },
            },
            {
                field: 'completedPercentage',
                headerName: 'COMPLETED PERCENTAGE',
                width:
                    eventColumnWidths.completedPercentage ??
                    defaultColumnWidths.completedPercentage,
                renderCell: (params) => {
                    return toPercentage(params.value);
                },
            },
        ];
    }, []);

    const [events, totalRowCount, refetchEvents] = useEvents(
        paginationModel.page,
        paginationModel.pageSize
    );

    const handleColumnResize = (params) => {
        setEventColumnWidths((prevWidths) => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PageHeader title="Manage Events" />

                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                    }}
                >
                    <DataGrid
                        columns={columns}
                        rows={events}
                        onRowClick={({ row }) => onShowEventDetail(row)}
                        paginationMode="server"
                        rowCount={totalRowCount}
                        pageSizeOptions={[10, 20, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{ toolbar: GridToolbar }}
                        disableRowSelectionOnClick={true}
                        onColumnWidthChange={handleColumnResize}
                        sx={{
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            // fontSize: '1rem',
                        }}
                    />
                </Box>
            </Box>
            <EventModal ref={repModelRef} eventDetail={eventDetail} />
        </>
    );
};

export default EventsTable;
