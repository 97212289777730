import axios from 'axios';
import { API_DOMAIN } from '../constants';
import { urlParseParams } from '../util/StringUtil';
import { formatDate } from '../util/DateUtil';

export async function getFittings(params) {
    if (params?.fromDate) {
        params.fromDate = formatDate(params.fromDate);
    }
    if (params?.toDate) {
        params.toDate = formatDate(params.toDate);
    }
    const response = await axios({
        url: API_DOMAIN + '/api/v2/adminPortal/fittings?' + urlParseParams(params),
        method: 'GET',
        headers: {
            Authorization: localStorage.getItem('MFE_TOKEN'),
        },
    });

    return {
        fittings: response.data,
        totalCount: parseInt(response.headers['x-total-count']),
    };
}
