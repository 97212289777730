import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getManagedAuthorities} from "../services/AccountService";
import {getReps} from "../services/UserService";
import {useLogout} from "./useLogout";
import {getRetailLocations} from "../services/RetailLocationService";

export function useRetailLocations(page, size, businessUnitCode, active, selectFitEbsCodeIds, selectFitCategoryIds,
                                   filterColumn, filterValue, filterOperator, enabled = true) {

    const [result, setResult] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const logout = useLogout();

    const {data, isError, error, refetch} = useQuery({
        queryKey: ['retailLocations', page, size, businessUnitCode, active, selectFitEbsCodeIds, selectFitCategoryIds,
            filterColumn, filterValue, filterOperator],
        queryFn: () => getRetailLocations(
            page,
            size,
            businessUnitCode,
            active,
            selectFitEbsCodeIds,
            selectFitCategoryIds,
            filterColumn,
            filterValue,
            filterOperator
        ),
        enabled: enabled,
        retry: false
    })

    useEffect(() => {
        if (isError && error.response && error.response.status) {
            if (error.response.status === 401) {
                logout();
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (data) {
            setResult(data.retailLocations);
            setTotalCount(data.totalCount);
        }
    }, [data]);

    return [result, totalCount, refetch];
}