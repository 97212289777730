import {forwardRef, useImperativeHandle, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import MyBooleanMark from "../../components/MyBooleanMark";
import {toPercentage} from "../../util/NumberUtil";

const EventModal = forwardRef(({eventDetail}, ref) => {
    const [open, setOpen] = useState(false);
    useImperativeHandle(ref, () => ({
        handleOpen
    }), []);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    if (!eventDetail) {
        return <></>;
    }
    return (<>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>{`EventId: #${eventDetail?.id} (${eventDetail?.eventType?.name}`} {`- ${eventDetail?.eventProductType?.name}`} {`${eventDetail?.indoor === true ? '- Indoor' : '- Outdoor'})`}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            <strong>Scheduled Start: </strong>
                            {eventDetail?.scheduledStart ? new Date(eventDetail?.scheduledStart).toLocaleString() : 'Unknown'}
                        </Typography>
                        <Typography><strong>Scheduled End: </strong>
                            {eventDetail?.scheduledEnd ? new Date(eventDetail?.scheduledEnd).toLocaleString() : 'Unknown'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Location</Typography>
                        {eventDetail?.location ? <>
                            <Typography>{eventDetail?.location?.name}</Typography>
                            <Typography>{eventDetail?.location?.address1}, {eventDetail?.location?.address2}</Typography>
                            <Typography>{eventDetail?.location?.city}, {eventDetail?.location?.state} {eventDetail?.location?.postalCode}</Typography>
                        </> : 'Unknown'}

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">ShipTo</Typography>
                        {eventDetail?.shipto ? <>
                            <Typography>{eventDetail?.shipto?.name}</Typography>
                            <Typography>{eventDetail?.shipto?.address1}, {eventDetail?.shipto?.address2}</Typography>
                            <Typography>{eventDetail?.shipto?.city}, {eventDetail?.shipto?.state ?? ''} {eventDetail?.shipto?.postalCode ?? ''}</Typography>
                        </> : 'Unknown'}

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Golf Pro</Typography>
                        <Typography>{eventDetail?.golfProFirstName ?? ''} {eventDetail?.golfProLastName ?? ''}</Typography>
                        <Typography>Email: {eventDetail?.golfProEmail ?? 'Unknown'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Additional Info</Typography>
                        <Typography><strong>Fitter Count:</strong> {eventDetail?.fitterCount}</Typography>
                        <Typography><strong>Fitting Duration:</strong> {eventDetail?.fittingDurationOpt?.name}
                        </Typography>
                        <Grid item display={'flex'}>
                            <Typography><strong>Build Station:</strong></Typography>
                            <MyBooleanMark
                                value={eventDetail?.onsiteClubBuilding}/>
                        </Grid>
                        <Typography><strong>Share Link: </strong>
                            <Link href={eventDetail?.shareLink} target="_blank"
                                  rel="noopener">{eventDetail?.shareLink}
                            </Link>
                        </Typography>
                        <Typography>
                            <strong>Filter Count: </strong>
                            {eventDetail?.fitterCount}
                        </Typography>
                        <Typography>
                            <strong>Business Unit: </strong>
                            {eventDetail?.businessUnitCode}
                        </Typography>
                        <Grid item display={'flex'}>
                            <Typography>
                                <strong>Active:</strong>
                            </Typography>
                            <MyBooleanMark
                                value={eventDetail?.active}/>
                        </Grid>
                        <Typography>
                            <strong>Language: </strong>
                            {eventDetail?.languageCode ?? ''}
                        </Typography>
                        <Typography>
                            <strong>Currency Code: </strong>
                            {eventDetail?.currencyCode ?? ''}
                        </Typography>
                        <Typography>
                            <strong>BookAbility Level: </strong>
                            {eventDetail?.bookabilityLevel?.bookabilityLevelName ?? 'Unknown'}
                        </Typography>
                        <Typography>
                            <strong>Specs Visibility: </strong>
                            {eventDetail?.specsVisibility?.name ?? ''}
                        </Typography>
                        <Typography>
                            <strong>Fitting Duration: </strong>
                            {eventDetail?.fittingDurationOpt?.name ?? ''}
                        </Typography>
                        <Typography>
                            <strong>Fitting Count: </strong>
                            {eventDetail?.fittingsCount ?? '0'}
                        </Typography>
                        <Typography>
                            <strong>Booked Percentage: </strong>
                            {toPercentage(eventDetail?.bookedPercentage)}
                        </Typography>
                        <Typography>
                            <strong>Completed Percentage: </strong>
                            {toPercentage(eventDetail?.completedPercentage)}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="contained"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>);
})
export default EventModal;