export function isAuthenticated() {
  return !!localStorage.getItem("MFE_TOKEN") && !!localStorage.getItem("MFE_CURRENT_USER");
}

export function getCurrentUser() {
  let currentUser = localStorage.getItem("MFE_CURRENT_USER");
  return currentUser ? JSON.parse(currentUser) : null;
}

export function clearCurrentUser() {
  localStorage.removeItem("MFE_TOKEN");
  localStorage.removeItem("MFE_CURRENT_USER");
}

export function canUserAccessAdminPortal(user) {
  // return user && user.adminPortalAccess && user.repType;
    if (!user) {
      return 'User not found';
    }
    if (!user.adminPortalAccess) {
        return 'Please Log In as an Admin Portal User.';
    }
    if (!user.repType) {
        return 'Cannot determine user type';
    }

    return null;
}
