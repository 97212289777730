import { Box, Grid } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyBooleanMark from '../../components/MyBooleanMark';
import PageHeader from '../../components/PageHeader';
import { REP_TYPES } from '../../constants';
import { useFittings } from '../../hooks/useFittings';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import routes from '../../routes';
import { getCurrentUser } from '../../util/AuthUtil';
import FittingsFilter from './FittingsFilter';

const defaultColumnWidths = {
    createdDate: 178,
    fittingActualEndTime: 221,
    schedulerNameEmailAddress: 309,
    lastUpdateNameEmailAddress: 302,
    golferEmail: 236,
    golferPhoneNumber: 211,
    golferLastName: 236,
    golferFirstName: 329,
    fittingTypeId: 180,
    locationCity: 247,
    locationAddress: 354,
    locationName: 310,
    shipto: 188,
    fittingScheduledStartTime: 330,
    fittingScheduledEndTime: 270,
    fittingActualStartTime: 265,
    completed: 155,
    recommendationsCount: 158,
    scheduledStart: 218,
    scheduledEnd: 214,
    ordersCount: 151,
    id: 76,
    locationCountry: 237,
    locationPostalCode: 254,
    locationState: 191,
    upcoming: 164,
    canceled: 189,
};

const FittingsTable = () => {
    console.log('Rendering FittingsTable');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });

    const [filter, setFilter] = useState({
        businessUnitCode: '',
        active: '',
        salesRegion: '',
        salesTerritory: '',
        repTypeId: '',
        searchString: '',
        fromDate: '',
        toDate: '',
    });
    const navigate = useNavigate();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (
            !routes
                .find((route) => route.key === 'fittings')
                .visibleRepTypes.includes(currentUser.repType.id)
        ) {
            navigate(REP_TYPES[currentUser.repType.id].defaultRoute);
        }
    }, [currentUser, navigate]);

    const [fittingsColumnWidths, setFittingsColumnWidths] = useLocalStorage(
        'fittingColumnWidths',
        {}
    );

    const columns = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: 'ID',
                width: fittingsColumnWidths.id ?? defaultColumnWidths.id,
            },
            {
                field: 'eventScheduledStart',
                headerName: 'EVENT START TIME',
                width:
                    fittingsColumnWidths.eventScheduledStart ??
                    defaultColumnWidths.eventScheduledStart,
                renderCell: (params) => {
                    if (params.value) {
                        return new Date(params.value).toLocaleString();
                    } else {
                        return 'Unknown';
                    }
                },
            },
            {
                field: 'eventScheduledEnd',
                headerName: 'EVENT END TIME',
                width:
                    fittingsColumnWidths.eventScheduledEnd ?? defaultColumnWidths.eventScheduledEnd,
                renderCell: (params) => {
                    if (params.value) {
                        return new Date(params.value).toLocaleString();
                    } else {
                        return 'Unknown';
                    }
                },
            },
            {
                field: 'shipto',
                headerName: 'EVENT SHIP TO',
                width: fittingsColumnWidths.shipto ?? defaultColumnWidths.shipto,
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.localeCompare(v2);
                },
            },
            {
                field: 'locationName',
                headerName: 'LOCATION NAME',
                width: fittingsColumnWidths.locationName ?? defaultColumnWidths.locationName,
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.localeCompare(v2);
                },
            },
            {
                field: 'locationAddress',
                headerName: 'LOCATION ADDRESS 1, ADDRESS 2',
                width: fittingsColumnWidths.locationName ?? defaultColumnWidths.locationName,
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.localeCompare(v2);
                },
            },
            {
                field: 'locationCity',
                headerName: 'LOCATION CITY',
                width: fittingsColumnWidths.locationCity ?? defaultColumnWidths.locationCity,
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.localeCompare(v2);
                },
            },
            {
                field: 'locationState',
                headerName: 'LOCATION STATE',
                width: fittingsColumnWidths.locationState ?? defaultColumnWidths.locationState,
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.localeCompare(v2);
                },
            },
            {
                field: 'locationPostalCode',
                headerName: 'LOCATION POSTAL CODE',
                width:
                    fittingsColumnWidths.locationPostalCode ??
                    defaultColumnWidths.locationPostalCode,
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.localeCompare(v2);
                },
            },
            {
                field: 'locationCountry',
                headerName: 'LOCATION COUNTRY',
                width: fittingsColumnWidths.locationCountry ?? defaultColumnWidths.locationCountry,
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.localeCompare(v2);
                },
            },
            {
                field: 'fittingTypeId',
                headerName: 'FITTING TYPE',
                width: fittingsColumnWidths.fittingTypeId ?? defaultColumnWidths.fittingTypeId,
                renderCell: (params) => {
                    let fittingType = params.row?.fittingType?.name;
                    return fittingType || 'Unknown';
                },
            },
            {
                field: 'fittingScheduledStart',
                headerName: 'FITTING SCHEDULED START TIME',
                width:
                    fittingsColumnWidths.fittingScheduledStartTime ??
                    defaultColumnWidths.fittingScheduledStartTime,
            },
            {
                field: 'fittingScheduledEnd',
                headerName: 'FITTING SCHEDULED END TIME',
                width:
                    fittingsColumnWidths.fittingScheduledEndTime ??
                    defaultColumnWidths.fittingScheduledEndTime,
            },
            {
                field: 'fittingActualStartTime',
                headerName: 'FITTING ACTUAL START TIME',
                width:
                    fittingsColumnWidths.fittingActualStartTime ??
                    defaultColumnWidths.fittingActualStartTime,
            },
            {
                field: 'fittingActualEndTime',
                headerName: 'FITTING ACTUAL END TIME',
                width:
                    fittingsColumnWidths.fittingActualEndTime ??
                    defaultColumnWidths.fittingActualEndTime,
            },
            {
                field: 'golferFirstName',
                headerName: 'GOLFER FIRST NAME',
                width: fittingsColumnWidths.golferFirstName ?? defaultColumnWidths.golferFirstName,
            },
            {
                field: 'golferLastName',
                headerName: 'GOLFER LAST NAME',
                width: fittingsColumnWidths.golferLastName ?? defaultColumnWidths.golferLastName,
            },
            {
                field: 'golferEmail',
                headerName: 'GOLFER EMAIL ADDRESS',
                width: fittingsColumnWidths.golferEmail ?? defaultColumnWidths.golferEmail,
            },
            {
                field: 'golferPhoneNumber',
                headerName: 'GOLFER PHONE NUMBER',
                width:
                    fittingsColumnWidths.golferPhoneNumber ?? defaultColumnWidths.golferPhoneNumber,
            },
            {
                field: 'upcoming',
                headerName: 'UPCOMING',
                width: fittingsColumnWidths.upcoming ?? defaultColumnWidths.upcoming,
                renderCell: (params) => <MyBooleanMark value={params.value} />,
            },
            {
                field: 'completed',
                headerName: 'COMPLETED',
                width: fittingsColumnWidths.active ?? defaultColumnWidths.active,
                renderCell: (params) => <MyBooleanMark value={params.value} />,
            },
            {
                field: 'canceled',
                headerName: 'CANCELED',
                width: fittingsColumnWidths.canceled ?? defaultColumnWidths.canceled,
                renderCell: (params) => <MyBooleanMark value={params.value} />,
            },
            {
                field: 'schedulerNameEmailAddress',
                headerName: 'SCHEDULER NAME AND EMAIL ADDRESS',
                width:
                    fittingsColumnWidths.schedulerNameEmailAddress ??
                    defaultColumnWidths.schedulerNameEmailAddress,
            },
            {
                field: 'lastUpdateNameEmailAddress',
                headerName: 'LAST UPDATED USER NAME AND EMAIL ADDRESS',
                width:
                    fittingsColumnWidths.lastUpdateNameEmailAddress ??
                    defaultColumnWidths.lastUpdateNameEmailAddress,
            },
            {
                field: 'recommendationsCount',
                headerName: 'RECOMMENDATION COUNT',
                width:
                    fittingsColumnWidths.recommendationCount ??
                    defaultColumnWidths.recommendationCount,
            },
            {
                field: 'ordersCount',
                headerName: 'ORDER COUNT',
                width: fittingsColumnWidths.ordersCount ?? defaultColumnWidths.ordersCount,
            },
        ];
    }, []);

    const [fittings, totalRowCount] = useFittings({
        page: paginationModel.page,
        size: paginationModel.pageSize,
        businessUnitCode: filter.businessUnitCode,
        active: filter.active,
        salesRegion: filter.salesRegion,
        salesTerritory: filter.salesTerritory,
        repTypeId: filter.repTypeId,
        searchString: filter.searchString,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
    });

    const handleColumnResize = (params) => {
        setFittingsColumnWidths((prevWidths) => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };
    const filterChangeFunctions = {
        businessUnitCode: (value) => {
            setFilter((prevState) => ({
                ...prevState,
                businessUnitCode: value,
            }));
        },
        active: (value) => {
            setFilter((prevState) => ({
                ...prevState,
                active: value,
            }));
        },
        fromDate: (value) => {
            setFilter((prevState) => ({
                ...prevState,
                fromDate: value,
            }));
        },
        toDate: (value) => {
            setFilter((prevState) => ({
                ...prevState,
                toDate: value,
            }));
        },
        salesRegion: (value) => {
            setFilter((prevState) => ({
                ...prevState,
                salesRegion: value,
                salesTerritory: '',
            }));
        },
        salesTerritory: (value) => {
            setFilter((prevState) => ({
                ...prevState,
                salesTerritory: value,
            }));
        },
        searchString: (value) => {
            setFilter((prevState) => ({
                ...prevState,
                searchString: value,
            }));
        },
        repTypeId: (value) => {
            setFilter((prevState) => ({
                ...prevState,
                repTypeId: value,
            }));
        },
    };

    function renderControlPanel() {
        return (
            <Box pt={1}>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Grid item xs={10}>
                        <FittingsFilter
                            filter={filter}
                            filterChangeFunctions={filterChangeFunctions}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                        }}
                    ></Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PageHeader title="Manage Fittings" children={renderControlPanel()} />
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                    }}
                >
                    <DataGrid
                        columns={columns}
                        rows={fittings}
                        paginationMode="server"
                        rowCount={totalRowCount}
                        pageSizeOptions={[10, 20, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{ toolbar: GridToolbar }}
                        disableRowSelectionOnClick={true}
                        onColumnWidthChange={handleColumnResize}
                        sx={{
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default FittingsTable;
