import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getFittings } from '../services/FittingService';
import { useLogout } from './useLogout';
import { fittingIsUpcoming } from '../util/DateUtil';

export function useFittings({
    businessUnitCode,
    repTypeId,
    active,
    salesRegion,
    salesTerritory,
    page,
    size,
    searchString,
    fromDate,
    toDate,
    enabled = true,
}) {
    const [fittings, setFittings] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const logout = useLogout();
    if (!page) {
        page = 0;
    }
    if (!size) {
        size = 20;
    }
    const { data, isError, error, refetch } = useQuery({
        queryKey: [
            'fittings',
            businessUnitCode,
            repTypeId,
            active,
            salesRegion,
            salesTerritory,
            page,
            size,
            searchString,
            fromDate,
            toDate,
        ],
        queryFn: () =>
            getFittings({
                businessUnitCode,
                repTypeId,
                active,
                salesRegion,
                salesTerritory,
                page,
                size,
                searchString,
                fromDate,
                toDate,
            }),
        enabled: enabled,
        retry: false,
    });

    useEffect(() => {
        if (isError && error.response && error.response.status) {
            if (error.response.status === 401) {
                logout();
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (data) {
            const transformData = data.fittings?.map((fitting) => {
                let eventShipTo =
                    fitting?.event?.shipto?.shiptoId || fitting?.event?.location?.shipTo;
                let location = fitting?.event?.facility || fitting?.event?.location;
                let locationAddress = 'Unknown';
                let locationCity = 'Unknown';
                let locationState = 'Unknown';
                let locationPostalCode = 'Unknown';
                let locationCountry = 'Unknown';
                if (location) {
                    locationAddress = [location?.address1, location?.address2]
                        .filter(Boolean)
                        .join(', ');
                    locationCity = location?.city;
                    locationState = location?.state;
                    locationPostalCode = location?.postalCode;
                    locationCountry = location?.country;
                }
                let fittingScheduledStart = fitting?.scheduledStart
                    ? new Date(fitting?.scheduledStart).toLocaleString()
                    : 'WALK UP';
                let fittingScheduledEnd = fitting?.scheduledEnd
                    ? new Date(fitting?.scheduledEnd).toLocaleString()
                    : 'NA';
                let fittingActualStartTime = fitting?.actualStart
                    ? new Date(fitting?.actualStart).toLocaleString()
                    : 'NA';
                let fittingActualEndTime = fitting?.actualEnd
                    ? new Date(fitting?.actualEnd).toLocaleString()
                    : 'NA';

                // Golfer
                const golfer = fitting?.golfer;
                let golferFirstName,
                    golferLastName,
                    golferEmail,
                    golferPhoneNumber,
                    schedulerNameEmailAddress,
                    lastUpdateNameEmailAddress;
                let canceled = fitting?.active == false;
                let upcoming = fittingIsUpcoming(fitting?.scheduledStart);
                golferFirstName = golfer?.firstName || 'Unknown';
                golferLastName = golfer?.lastName || 'Unknown';
                golferEmail = golfer?.email || 'Unknown';
                golferPhoneNumber = golfer?.phoneNumber || 'Unknown';
                // Create User
                const createdUser = fitting?.createdUser;
                if (createdUser) {
                    let { name, email } = createdUser;
                    schedulerNameEmailAddress = [name, email].filter(Boolean).join(' - ');
                } else {
                    schedulerNameEmailAddress = 'Unknown';
                }
                // Update User
                const updatedUser = fitting?.updatedUser;
                if (updatedUser) {
                    let { name, email } = updatedUser;
                    lastUpdateNameEmailAddress = [name, email].filter(Boolean).join(' - ');
                } else {
                    lastUpdateNameEmailAddress = 'Unknown';
                }
                return {
                    ...fitting,
                    shipto: eventShipTo || 'Unknown',
                    locationName: location ? location?.name : 'Unknown',
                    locationAddress,
                    locationCity,
                    locationState,
                    locationCountry,
                    locationPostalCode,
                    eventScheduledStart: fitting?.event?.scheduledStart,
                    eventScheduledEnd: fitting?.event?.scheduledEnd,
                    fittingTypeId: fitting?.fittingType?.name || 'Unknown',
                    fittingScheduledStart,
                    fittingScheduledEnd,
                    fittingActualStartTime,
                    fittingActualEndTime,
                    golferFirstName,
                    golferLastName,
                    golferEmail,
                    golferPhoneNumber,
                    schedulerNameEmailAddress,
                    lastUpdateNameEmailAddress,
                    canceled,
                    upcoming
                };
            });
            setFittings(transformData);
            setTotalCount(data.totalCount);
        }
    }, [data]);

    return [fittings, totalCount, refetch];
}
