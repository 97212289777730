import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';

import MySelect from '../../components/MySelect';
import { useManagedRepTypes } from '../../hooks/useManagedRepTypes';
import { useRepPermissions } from '../../hooks/useRepPermissions';
import MyTextField from '../../components/MyTextField';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useSalesRegions } from '../../hooks/useSalesRegions';
import { useUser } from '../../hooks/useUser';
import { DATA_TYPES, REP_FIELDS, RETAIL_LOCATION_FIELDS, VIEW_TYPES } from '../../constants';
import MyCheckbox from '../../components/MyCheckbox';
import { canLeftUserEditRightUser } from '../../util/UserUtil';
import MyMultiSelectCheckboxes from '../../components/MyMultiSelectCheckboxes';
import { useSalesTerritories } from '../../hooks/useSalesTerritories';
import { useUserUpdate } from '../../hooks/useUserUpdate';
import { useBusinessUnitCodes } from '../../hooks/useBusinessUnitCodes';
import { useUserCreate } from '../../hooks/useUserCreate';
import { extractFirstAndLastNameFromEmail } from '../../util/StringUtil';
import { useCountries } from '../../hooks/useCountries';
import { useRetailLocation } from '../../hooks/useRetailLocation';
import { useSelectFitEbsCodes } from '../../hooks/useSelectFitEbsCodes';
import { useSelectFitCategories } from '../../hooks/useSelectFitCategories';
import { useSelectFitLogoTypes } from '../../hooks/useSelectFitLogoTypes';
import { useRetailLocationUpdate } from '../../hooks/useRetailLocationUpdate';
import { useRetailLocationCreate } from '../../hooks/useRetailLocationCreate';
import ShiptoSearch from '../../components/ShiptoSearch';

const getInitialFormData = () => {
    const initialFormData = {};

    RETAIL_LOCATION_FIELDS.forEach((field) => {
        initialFormData[field.name] = {};
        switch (field.viewType) {
            case VIEW_TYPES.TEXT:
                initialFormData[field.name].value = '';
                break;
            case VIEW_TYPES.SINGLE_SELECT:
                initialFormData[field.name].value = '';
                initialFormData[field.name].options =
                    field.name === 'countryCode' ? [{ id: 'US', name: 'US' }] : [];
                break;
            case VIEW_TYPES.MULTI_SELECT:
                initialFormData[field.name].value = [];
                initialFormData[field.name].options = [];
                break;
            case VIEW_TYPES.CHECKBOX:
                initialFormData[field.name].value = field.defaultValue;
                break;
        }
    });

    return initialFormData;
};

const RetailLocationModal = forwardRef(({ refetchRetailLocations }, ref) => {
    console.log('Rendering RetailLocationModal.');

    const [resetKey, setResetKey] = useState(0);
    const [retailLocationId, setRetailLocationId] = useState(0);
    const [open, setOpen] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [formData, setFormData] = useState(getInitialFormData);
    const [mode, setMode] = useState('create');
    const [confirmationDialog, setConfirmationDialog] = useState({ open: false });

    console.log('resetKey: ' + resetKey);
    console.log('formData: ', formData);

    const overlayRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            handleOpen,
        }),
        []
    );

    const retailLocation = useRetailLocation(retailLocationId, open, (data) => {
        setFormData((prev) => {
            console.log('useRetailLocation callback');
            let newFormData = { ...prev };
            RETAIL_LOCATION_FIELDS.forEach((field) => {
                switch (field.dataType) {
                    case DATA_TYPES.STRING:
                    case DATA_TYPES.TIMESTAMP:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name] ?? '',
                        };
                        break;
                    case DATA_TYPES.BOOLEAN:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: !!data[field.name],
                        };
                        break;
                    case DATA_TYPES.STRING_ARRAY:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name] ?? [],
                        };
                        break;
                    case DATA_TYPES.OBJECT:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name]?.id ?? '',
                        };
                        break;
                }
            });
            return newFormData;
        });
    });

    useSelectFitEbsCodes(open, resetKey, (data) => {
        setFormData((prev) => {
            return { ...prev, selectFitEbsCode: { ...prev.selectFitEbsCode, options: data } };
        });
    });

    useSelectFitCategories(open, resetKey, (data) => {
        setFormData((prev) => {
            return { ...prev, selectFitCategory: { ...prev.selectFitCategory, options: data } };
        });
    });

    useSelectFitLogoTypes(open, resetKey, (data) => {
        setFormData((prev) => {
            return { ...prev, selectFitLogoType: { ...prev.selectFitLogoType, options: data } };
        });
    });

    useBusinessUnitCodes(open && mode === 'create', resetKey, (data) => {
        console.log('useBusinessUnitCodes callback');
        setFormData((prev) => {
            return { ...prev, businessUnitCode: { ...prev.businessUnitCode, options: data } };
        });
    });

    useCountries(formData.businessUnitCode.value, open && mode === 'create', (data) => {
        console.log('useCountries callback');
        setFormData((prev) => {
            if (mode === 'update') {
                return { ...prev, countryCode: { ...prev.countryCode, options: data } };
            } else {
                const defaultCountry = data ? data[0]?.id : '';
                return {
                    ...prev,
                    countryCode: { ...prev.countryCode, options: data, value: defaultCountry },
                };
            }
        });
    });
    // const [currentUser, updateCurrentUser] = useCurrentUser();

    const retailLocationUpdateMutation = useRetailLocationUpdate();
    const retailLocationCreateMutation = useRetailLocationCreate();

    const handleInputChange = (key, value) => {
        setFormData((prev) => {
            let newFormData = { ...prev, [key]: { ...prev[key], value: value } };
            return newFormData;
        });
    };

    const handleShiptoChange = (value) => {
        if (value) {
            setFormData((prev) => {
                let newFormData = {
                    ...prev,
                    shiptoId: { ...prev['shiptoId'], value: value.shiptoId },
                    displayName: { ...prev['displayName'], value: value.name ?? '' },
                    street: {
                        ...prev['street'],
                        value: [value.address1, value.address2, value.address3].join(', '),
                    },
                    city: { ...prev['city'], value: value.city },
                    state: { ...prev['state'], value: value.state },
                    postalCode: { ...prev['postalCode'], value: value.postalCode },
                    countryCode: { ...prev['countryCode'], value: value.country },
                };
                return newFormData;
            });
        }
    };

    const handleOpen = (retailLocationId) => {
        if (!!retailLocationId) {
            setRetailLocationId(retailLocationId);
            setMode('update');
        } else {
            setRetailLocationId(0);
            setMode('create');
        }
        setOpen(true);
    };

    const handleClose = () => {
        setErrorMessages({});
        setOpen(false);
        setRetailLocationId(0);
        // setFormData(getInitialFormData());
        setFormData((prev) => {
            // let newFormData = {...prev};
            // RETAIL_LOCATION_FIELDS.forEach((field) => {
            //     switch (field.viewType) {
            //         case VIEW_TYPES.TEXT:
            //             newFormData[field.name].value = '';
            //             break;
            //         case VIEW_TYPES.SINGLE_SELECT:
            //             newFormData[field.name].value = '';
            //             newFormData[field.name].options = [];
            //             break;
            //         case VIEW_TYPES.MULTI_SELECT:
            //             newFormData[field.name].value = [];
            //             newFormData[field.name].options = [];
            //             break;
            //         case VIEW_TYPES.CHECKBOX:
            //             newFormData[field.name].value = field.defaultValue;
            //             break;
            //     }
            // });
            // return newFormData;
            return getInitialFormData();
        });
        setResetKey((prev) => prev + 1);
    };

    const composePayload = () => {
        let payload = {};
        RETAIL_LOCATION_FIELDS.forEach((field) => {
            if (field.key) {
                switch (field.dataType) {
                    case DATA_TYPES.BOOLEAN:
                        payload[field.key] = !!formData[field.name].value;
                        break;
                    case DATA_TYPES.STRING_ARRAY:
                        let filteredValue = formData[field.name].value.filter((id) =>
                            formData[field.name].options.some((option) => option.id === id)
                        );
                        if (filteredValue.length > 0) {
                            payload[field.key] = filteredValue;
                        }
                        break;
                    default:
                        if (!!formData[field.name].value) {
                            payload[field.key] = formData[field.name].value;
                        }
                        break;
                }
            }
        });
        if (retailLocationId) {
            payload.id = retailLocationId;
        }

        return payload;
    };

    const onCreateSuccess = (response) => {
        refetchRetailLocations();
        handleClose();
        // setConfirmationDialog({open: true, emailSent: response.data.repCreatedEmailSent, email: response.data.email});
    };

    const onUpdateSuccess = () => {
        refetchRetailLocations();
        handleClose();
    };

    const onSubmitError = (error) => {
        setErrorMessages({ retailLocationView: error.response.data.message });
    };

    const handleSubmit = () => {
        // handleClose();
        const payload = composePayload();
        const action = mode === 'create' ? 'Creating' : 'Updating';
        console.log(`${action} user with payload:`);
        console.log(payload);
        if (mode === 'create') {
            retailLocationCreateMutation.mutate(payload, {
                onSuccess: onCreateSuccess,
                onError: onSubmitError,
            });
        } else {
            retailLocationUpdateMutation.mutate(payload, {
                onSuccess: onUpdateSuccess,
                onError: onSubmitError,
            });
        }
        // handleClose();
    };

    const calculateOverlayTextAngle = (element) => {
        if (element) {
            const { width, height } = element.getBoundingClientRect();
            const radianAngle = Math.atan2(height, width);
            return -radianAngle * (180 / Math.PI);
        }
        return 0;
    };

    // const isFieldEditable = (field) => {
    //     if (mode === 'create' && field.modes.includes('create')) {
    //         return true;
    //     }
    //     // if (!canBeEditedByCurrentUser) {
    //     //     return false;
    //     // }
    //     // if (currentUser.id === user.id) {
    //     //     return field.selfEditable;
    //     // }
    //     // return field.editableByRepTypeIds.includes(currentUser.repType.id);
    //     return false
    // }

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="md"
                PaperProps={{
                    component: 'form',
                    onSubmit: (e) => {
                        e.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle zIndex={3}>
                    {retailLocationId && retailLocation
                        ? retailLocation.displayName
                        : 'New Retail Location'}
                    {mode === 'update' && (
                        <Button
                            sx={{
                                float: 'right',
                                textDecoration: 'underline',
                            }}
                            onClick={() => {
                                setFormData((prev) => {
                                    return {
                                        ...prev,
                                        active: { ...prev.active, value: !prev.active.value },
                                    };
                                });
                            }}
                        >
                            {formData.active.value ? 'Deactivate' : 'Re-activate'}
                        </Button>
                    )}
                </DialogTitle>
                <DialogContent ref={overlayRef} dividers={true}>
                    {!formData.active.value && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backdropFilter: 'blur(3px)',
                                zIndex: 2,
                            }}
                        >
                            <Typography
                                variant="h1"
                                letterSpacing={10}
                                fontWeight={700}
                                textTransform={'uppercase'}
                                sx={{
                                    transform: `rotate(${calculateOverlayTextAngle(overlayRef.current)}deg)`,
                                }}
                            >
                                Inactive
                            </Typography>
                        </Box>
                    )}
                    <Grid container spacing={1}>
                        {RETAIL_LOCATION_FIELDS.filter((field) => {
                            return field.modes.includes(mode);
                        }).map((field) => {
                            if (field.name === 'shiptoId') {
                                console.log(
                                    'RetailLocationModal/ShiptoSearch - businessUnitCode: ' +
                                        formData.businessUnitCode.value +
                                        ', initialValue: ' +
                                        formData.shiptoId.value
                                );
                                return (
                                    <Grid item xs={12} key={field.name}>
                                        <ShiptoSearch
                                            label={field.label}
                                            businessUnitCode={formData.businessUnitCode.value}
                                            editable={!!formData['businessUnitCode'].value}
                                            initialValue={formData.shiptoId.value}
                                            onChange={(value) => handleShiptoChange(value)}
                                        />
                                    </Grid>
                                );
                            }
                            switch (field.viewType) {
                                case VIEW_TYPES.TEXT:
                                    let textValue = formData[field.name].value;
                                    if (field.dataType === DATA_TYPES.TIMESTAMP) {
                                        textValue = textValue
                                            ? new Date(textValue).toLocaleString()
                                            : '';
                                    }
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyTextField
                                                value={textValue}
                                                label={field.label}
                                                type="text"
                                                editable={!!formData['businessUnitCode'].value}
                                                required={
                                                    mode === 'create' && field.requiredForCreate
                                                }
                                                maxLength={field.maxLength}
                                                onChange={(event) =>
                                                    handleInputChange(
                                                        field.name,
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.SINGLE_SELECT:
                                    let singleSelectValue = formData[field.name].value;
                                    let options = formData[field.name].options;
                                    if (options.length === 0) {
                                        if (field.dataType === DATA_TYPES.OBJECT) {
                                            options = [
                                                {
                                                    id: retailLocation[field.name]?.id ?? '',
                                                    name: retailLocation[field.name]?.name ?? '',
                                                },
                                            ];
                                        } else {
                                            options = [
                                                {
                                                    id: retailLocation[field.name] ?? '',
                                                    name: retailLocation[field.name] ?? '',
                                                },
                                            ];
                                        }
                                    }
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MySelect
                                                key={resetKey}
                                                label={field.label}
                                                options={options}
                                                value={singleSelectValue}
                                                editable={
                                                    field.name === 'businessUnitCode' ||
                                                    !!formData['businessUnitCode'].value
                                                }
                                                required={
                                                    mode === 'create' && field.requiredForCreate
                                                }
                                                autoSelect={singleSelectValue || mode === 'create'}
                                                onChange={(value) =>
                                                    handleInputChange(field.name, value)
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.MULTI_SELECT:
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyMultiSelectCheckboxes
                                                title={field.label}
                                                options={formData[field.name].options}
                                                value={formData[field.name].value}
                                                onChange={(value) =>
                                                    handleInputChange(field.name, value)
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.CHECKBOX:
                                    let checkBoxValue = formData[field.name].value;
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyCheckbox
                                                checked={!!checkBoxValue}
                                                label={field.label}
                                                editable={!!formData['businessUnitCode'].value}
                                                onChange={(event) =>
                                                    handleInputChange(
                                                        field.name,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        </Grid>
                                    );
                            }
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        m: 2,
                        zIndex: 3,
                    }}
                >
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        type="submit"
                        variant="contained"
                        autoFocus
                        // disabled={!formEditable}
                    >
                        {mode === 'update' ? 'UPDATE' : 'CREATE'}
                    </Button>
                </DialogActions>
                {errorMessages.repView && (
                    <Box mt={2}>
                        <Alert severity="error">{errorMessages.repView}</Alert>
                    </Box>
                )}
            </Dialog>
            <Dialog open={confirmationDialog.open}>
                <DialogTitle>{confirmationDialog.emailSent ? 'Success' : 'Warning'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmationDialog.emailSent
                            ? 'A welcome email has been sent to ' + confirmationDialog.email
                            : 'Failed to send welcome email to ' + confirmationDialog.email}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmationDialog((prev) => ({ ...prev, open: false }))}
                        color="primary"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default RetailLocationModal;
