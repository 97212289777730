import { IconButton, InputAdornment, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import MyDatePicker from '../../components/MyDatePicker';
import MySelect from '../../components/MySelect';
import { REP_TYPES } from '../../constants';
import { useBusinessUnitCodes } from '../../hooks/useBusinessUnitCodes';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useSalesRegions } from '../../hooks/useSalesRegions';
import { useSalesTerritories } from '../../hooks/useSalesTerritories';

const activatedOptions = [
    { id: '', name: '-- CLEAR --' },
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
];

const FittingsFilter = ({ filter, filterChangeFunctions }) => {
    console.log('Rendering FittingFilter');

    const [salesRegionOptions, setSalesRegionOptions] = useState([]);
    const [salesTerritoryOptions, setSalesTerritoryOptions] = useState([]);
    const [searchInput, setSearchInput] = useState(filter.searchString);

    const [currentUser, _updateCurrentUser] = useCurrentUser(false);
    const [businessUnitCodes, setBusinessUnitCodes] = useState([]);
    useBusinessUnitCodes(true, null, (data) => {
        let options = [...data];
        if (options.length > 1) {
            options = [{ id: '', name: '-- CLEAR --' }, ...options];
        }
        if (options.length === 1) {
            filterChangeFunctions.businessUnitCode(options[0].id);
        }
        setBusinessUnitCodes(options);
    });
    const repTypeOptions = useMemo(() => {
        let options = [];
        if (currentUser && currentUser.repType) {
            options = REP_TYPES[currentUser.repType.id].visibleRepTypeIds.map((id) => {
                return { id, name: REP_TYPES[id].name };
            });
        }
        if (options.length > 0) {
            return [{ id: '', name: '-- CLEAR --' }, ...options];
        } else {
            return options;
        }
    }, [currentUser.repType.id]);

    useSalesRegions(
        filter.businessUnitCode,
        currentUser.id,
        [1, 2].includes(currentUser.repType.id),
        (data) => {
            // Sort by repsCount desc
            data.sort((a, b) => b.repsCount - a.repsCount);

            // Then change name to name (repsCount)
            const updatedData = data.map((item) => ({
                ...item,
                name: `${item.name} (${item.repsCount} ${item.repsCount > 1 ? 'reps' : 'rep'})`,
            }));

            let options = [{ id: '', name: '-- CLEAR --' }, ...updatedData];
            setSalesRegionOptions(options);
        }
    );

    useSalesTerritories(
        filter.businessUnitCode,
        filter.salesRegion,
        [1, 2, 3].includes(currentUser.repType.id),
        (data) => {
            if (data.length > 0) {
                let options = [{ id: '', name: '-- CLEAR --' }, ...data];
                setSalesTerritoryOptions(options);
            } else {
                setSalesTerritoryOptions(data);
            }
        }
    );

    useEffect(() => {
        setSearchInput(filter.searchString);
    }, [filter.searchString]);

    // Debounce for search input
    useEffect(() => {
        const handler = setTimeout(() => {
            filterChangeFunctions.searchString(searchInput);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchInput]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <TextField
                    label="Search"
                    value={searchInput}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setSearchInput(e.target.value)}
                    sx={{
                        backgroundColor: 'white',
                    }}
                    InputProps={{
                        endAdornment: searchInput && (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear search"
                                    onClick={() => setSearchInput('')}
                                    edge="end"
                                >
                                    <GridCloseIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <MyDatePicker
                    label="From Date"
                    variant="outlined"
                    fullWidth
                    value={filter.fromDate}
                    maxDate={filter.toDate || null}
                    onChange={filterChangeFunctions.fromDate}
                    sx={{
                        backgroundColor: 'white',
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <MyDatePicker
                    label="To Date"
                    variant="outlined"
                    fullWidth
                    value={filter.toDate}
                    minDate={filter.fromDate || null}
                    onChange={filterChangeFunctions.toDate}
                    sx={{
                        backgroundColor: 'white',
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <MySelect
                    label="Activated"
                    options={activatedOptions}
                    value={filter.active}
                    onChange={filterChangeFunctions.active}
                />
            </Grid>
            {currentUser.repType.id === 1 && (
                <Grid item xs={2}>
                    <MySelect
                        label="Business Unit"
                        options={businessUnitCodes}
                        value={filter.businessUnitCode}
                        onChange={(value) => {
                            filterChangeFunctions.businessUnitCode(value);
                        }}
                    />
                </Grid>
            )}
            {[1, 2].includes(currentUser.repType.id) && (
                <Grid item xs={2}>
                    <MySelect
                        label="Sales Region"
                        options={salesRegionOptions}
                        value={filter.salesRegion}
                        autoSelect={true}
                        onChange={(value) => {
                            filterChangeFunctions.salesRegion(value);
                        }}
                    />
                </Grid>
            )}
            {[1, 2, 3].includes(currentUser.repType.id) && (
                <Grid item xs={2}>
                    <MySelect
                        label="Sales Territory"
                        options={salesTerritoryOptions}
                        value={filter.salesTerritory}
                        onChange={filterChangeFunctions.salesTerritory}
                    />
                </Grid>
            )}
            {[1, 2, 3].includes(currentUser.repType.id) && (
                <Grid item xs={2}>
                    <MySelect
                        label="User Type"
                        options={repTypeOptions}
                        value={filter.repTypeId}
                        onChange={filterChangeFunctions.repTypeId}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default FittingsFilter;
