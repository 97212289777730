import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ClearIcon, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
function CustomInputAdornment(props) {
    const { hasValue, children, onChange, sx, ...other } = props;
    return (
        <InputAdornment {...other}>
            {hasValue && (
                <IconButton onClick={() => onChange('')} size="small">
                    <ClearIcon />
                </IconButton>
            )}
            {children}
        </InputAdornment>
    );
}

const MyDatePicker = ({ label, value, minDate, maxDate, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label || 'Select Date'}
                value={value || null}
                minDate={minDate}
                maxDate={maxDate}
                sx={{ background: '#fff' }}
                slots={{ inputAdornment: CustomInputAdornment }}
                slotProps={{
                    inputAdornment: { hasValue: !!value, onChange: onChange },
                }}
                onChange={(newValue) => {
                    onChange(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
            />
        </LocalizationProvider>
    );
};
export default MyDatePicker;
