import {
    Avatar,
    Box,
    Button,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    useTheme
} from "@mui/material";
import routes from "../routes";
import {NavLink, useNavigate} from "react-router-dom";
import mfeLogo from "../assets/images/mfe-logo.svg";
import currentUserIcon from "../assets/images/current-user-icon@3x.png";
import signOutIcon from "../assets/images/sign-out-icon.svg";
import {getCurrentUser, isAuthenticated} from "../util/AuthUtil";
import {useLogout} from "../hooks/useLogout";
import MySidebarItem from "../components/MySidebarItem";
import {useCurrentUser} from "../hooks/useCurrentUser";
import AccountModal from "./account/AccountModal";
import {useRef} from "react";

const drawerWidth = 140;

const Sidebar = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const logout = useLogout();
    const [currentUser, _] = useCurrentUser();
    const accountModelRef = useRef();
    // function handleSignOut() {
    //   clearCurrentUser();
    //   navigate("/sign-in");
    // }

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Box
                component="img"
                src={mfeLogo}
                alt="MFE Logo"
                sx={{
                    px: 5.375,
                    py: 2.375,
                }}
            />
            <Divider />
            <List sx={{ p: 0 }}>
                {routes
                    .filter(
                        ({ key, visibleRepTypes }) =>
                            key !== 'sign-in' && visibleRepTypes.includes(currentUser?.repType?.id)
                    )
                    .map(({ name, icon, key, path }) => {
                        return (
                            <ListItem key={key} sx={{ p: 0 }}>
                                <NavLink
                                    to={path}
                                    style={({ isActive }) => ({
                                        textDecoration: 'none',
                                        color: isActive ? 'white' : 'black',
                                        width: '100%',
                                        backgroundColor: isActive
                                            ? theme.palette.secondary.main
                                            : 'white',
                                    })}
                                >
                                    {/*<ListItemButton>*/}
                                    {/*    <ListItemText primary={name}/>*/}
                                    {/*</ListItemButton>*/}
                                    <ListItemButton
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            py: 1,
                                            px: 0.8,
                                        }}
                                    >
                                        <ListItemAvatar sx={{ p: 1 }}>
                                            <Avatar src={icon} alt={name} />
                                        </ListItemAvatar>
                                        <ListItemText primary={name} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                        );
                    })}
            </List>
            <Divider />
            {isAuthenticated() ? (
                <Box
                    sx={{
                        mt: 'auto',
                    }}
                >
                    <Divider />
                    {/*<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', py: 1.5}}>*/}
                    {/*    <Box*/}
                    {/*        component="img"*/}
                    {/*        src={currentUserIcon}*/}
                    {/*        alt="Current User Icon"*/}
                    {/*        sx={{*/}
                    {/*            width: 40,*/}
                    {/*            height: 40,*/}
                    {/*            borderRadius: '50%',*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    <Typography variant="body2" align="center" mt="auto">*/}
                    {/*        {[getCurrentUser().firstName, getCurrentUser().lastName].filter(Boolean).join(' ')}*/}
                    {/*    </Typography>*/}
                    {/*</Box>*/}
                    <MySidebarItem
                        src={currentUserIcon}
                        alt="Current User Icon"
                        text={[getCurrentUser().firstName, getCurrentUser().lastName]
                            .filter(Boolean)
                            .join(' ')}
                        onClick={() => {
                            accountModelRef?.current?.handleOpen(getCurrentUser()?.id || 0);
                        }}
                    />
                    <AccountModal ref={accountModelRef} />
                    {/*<Button*/}
                    {/*    component="button"*/}
                    {/*    variant="contained"*/}
                    {/*    fullWidth*/}
                    {/*    onClick={logout}*/}
                    {/*>*/}
                    {/*    <Typography>Sign Out</Typography>*/}
                    {/*</Button>*/}
                    <MySidebarItem
                        src={signOutIcon}
                        alt="Sign Out Icon"
                        text="Sign Out"
                        onClick={logout}
                        backgroundColor={theme.palette.divider}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        mt: 'auto',
                        p: 2,
                    }}
                >
                    <Button variant="contained" fullWidth onClick={() => navigate('/sign-in')}>
                        <Typography>Sign In</Typography>
                    </Button>
                </Box>
            )}
        </Drawer>
    );
};

export default Sidebar;
