import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { forwardRef, useImperativeHandle, useState } from 'react';

import MyCheckbox from '../../components/MyCheckbox';
import MyMultiSelectCheckboxes from '../../components/MyMultiSelectCheckboxes';
import MySelect from '../../components/MySelect';
import MyTextField from '../../components/MyTextField';
import { ACCOUNT_FIELDS, DATA_TYPES, VIEW_TYPES } from '../../constants';
import { useCountries } from '../../hooks/useCountries';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useRepPermissions } from '../../hooks/useRepPermissions';
import { useSalesRegions } from '../../hooks/useSalesRegions';
import { useSalesTerritories } from '../../hooks/useSalesTerritories';
import { useUser } from '../../hooks/useUser';
import { useUserUpdate } from '../../hooks/useUserUpdate';
import { extractFirstAndLastNameFromEmail } from '../../util/StringUtil';

const getInitialFormData = () => {
    const initialFormData = {};

    ACCOUNT_FIELDS.forEach((field) => {
        initialFormData[field.name] = {};
        switch (field.viewType) {
            case VIEW_TYPES.TEXT:
                initialFormData[field.name].value = '';
                break;
            case VIEW_TYPES.SINGLE_SELECT:
                initialFormData[field.name].value = '';
                initialFormData[field.name].options = [];
                break;
            case VIEW_TYPES.MULTI_SELECT:
                initialFormData[field.name].value = [];
                initialFormData[field.name].options = [];
                break;
            case VIEW_TYPES.CHECKBOX:
                initialFormData[field.name].value = field.defaultValue;
                break;
        }
    });

    return initialFormData;
};

const AccountModal = forwardRef((props, ref) => {
    console.log('Rendering AccountModal');

    const [resetKey, setResetKey] = useState(0);
    const [userId, setUserId] = useState(0);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [successMessages, setSuccessMessages] = useState({});
    const [formData, setFormData] = useState(getInitialFormData);
    const [mode, setMode] = useState('update');

    console.log('resetKey: ' + resetKey);

    useImperativeHandle(
        ref,
        () => ({
            handleOpen,
        }),
        []
    );

    const user = useUser(userId, open, (data) => {
        setFormData((prev) => {
            console.log('useUser callback');
            let newFormData = { ...prev };
            ACCOUNT_FIELDS.forEach((field) => {
                switch (field.dataType) {
                    case DATA_TYPES.STRING:
                    case DATA_TYPES.TIMESTAMP:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name] ?? '',
                        };
                        break;
                    case DATA_TYPES.BOOLEAN:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: !!data[field.name],
                        };
                        break;
                    case DATA_TYPES.STRING_ARRAY:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name] ?? [],
                        };
                        break;
                    case DATA_TYPES.OBJECT:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name]?.id ?? '',
                        };
                        break;
                }
            });
            return newFormData;
        });
    });

    const [currentUser, updateCurrentUser] = useCurrentUser();

    const isRepFieldEditable = (field) => {
        return field.selfEditable;
    };

    useCountries(
        formData.businessUnitCode.value,
        open && isRepFieldEditable(ACCOUNT_FIELDS.find((field) => field.name === 'countryCode')),
        (data) => {
            console.log('useCountries callback');
            setFormData((prev) => {
                return {
                    ...prev,
                    countryCode: { ...prev.countryCode, options: data },
                };
            });
        }
    );

    useSalesRegions(
        formData.businessUnitCode.value,
        currentUser.id,
        open && isRepFieldEditable(ACCOUNT_FIELDS.find((field) => field.name === 'salesRegion')),
        (data) => {
            setFormData((prev) => {
                return { ...prev, salesRegion: { ...prev.salesRegion, options: data } };
            });
        }
    );

    useSalesTerritories(
        formData.businessUnitCode.value,
        formData.salesRegion.value,
        open &&
            isRepFieldEditable(ACCOUNT_FIELDS.find((field) => field.name === 'salesTerritories')),
        (data) => {
            setFormData((prev) => {
                return {
                    ...prev,
                    salesTerritories: { ...prev.salesTerritories, options: data },
                };
            });
        }
    );

    useRepPermissions(
        formData.businessUnitCode.value,
        formData.repType.value,
        open && isRepFieldEditable(ACCOUNT_FIELDS.find((field) => field.name === 'repPermission')),
        resetKey,
        (data) => {
            setFormData((prev) => {
                return {
                    ...prev,
                    repPermission: { ...prev.repPermission, options: data },
                };
            });
        }
    );

    const userUpdateMutation = useUserUpdate();

    const handleInputChange = (key, value) => {
        setFormData((prev) => {
            let newFormData = { ...prev, [key]: { ...prev[key], value: value } };
            if (key === 'email') {
                if (prev.login.value === prev.email.value) {
                    newFormData.login.value = value;
                }
                let prevDerivedNames = extractFirstAndLastNameFromEmail(prev.email.value);
                let newDerivedNames = extractFirstAndLastNameFromEmail(value);
                if (prevDerivedNames.firstName === prev.firstName.value) {
                    newFormData.firstName.value = newDerivedNames.firstName;
                }
                if (prevDerivedNames.lastName === prev.lastName.value) {
                    newFormData.lastName.value = newDerivedNames.lastName;
                }
            }
            return newFormData;
        });
    };

    const handleOpen = (userId) => {
        setUserId(userId);
        setMode('update');
        setOpen(true);
    };

    const handleClose = () => {
        setUserId(0);
        setErrorMessages({});
        setSuccessMessages({});
        setFormData((prev) => {
            let newFormData = { ...prev };
            ACCOUNT_FIELDS.forEach((field) => {
                switch (field.viewType) {
                    case VIEW_TYPES.TEXT:
                        newFormData[field.name].value = '';
                        break;
                    case VIEW_TYPES.SINGLE_SELECT:
                        newFormData[field.name].value = '';
                        newFormData[field.name].options = [];
                        break;
                    case VIEW_TYPES.MULTI_SELECT:
                        newFormData[field.name].value = [];
                        newFormData[field.name].options = [];
                        break;
                    case VIEW_TYPES.CHECKBOX:
                        newFormData[field.name].value = field.defaultValue;
                        break;
                }
            });
            return newFormData;
        });
        setOpen(false);
        setResetKey((prev) => prev + 1);
    };

    const composePayload = () => {
        let payload = {};
        ACCOUNT_FIELDS.forEach((field) => {
            if (field.key && isRepFieldEditable(field)) {
                switch (field.dataType) {
                    case DATA_TYPES.BOOLEAN:
                        payload[field.key] = !!formData[field.name].value;
                        break;
                    case DATA_TYPES.STRING_ARRAY:
                        let filteredValue = formData[field.name].value.filter((id) =>
                            formData[field.name].options.some((option) => option.id === id)
                        );
                        if (filteredValue.length > 0) {
                            payload[field.key] = filteredValue;
                        }
                        break;
                    default:
                        if (!!formData[field.name].value) {
                            payload[field.key] = formData[field.name].value;
                        }
                        break;
                }
            }
        });
        if (userId) {
            payload.id = userId;
        }

        return payload;
    };

    const onUpdateSuccess = (updatedData) => {
        setLoading(false);
        updateCurrentUser((prev) => ({
            ...prev,
            ...updatedData,
        }));
        setSuccessMessages({ repView: 'Account updated successfully!' });
        setTimeout(() => {
            handleClose();
        }, 2500);
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    };

    const onSubmitError = (error) => {
        setLoading(false);
        setErrorMessages({ repView: error.response.data.message });
    };

    const handleSubmit = () => {
        // handleClose();
        const payload = composePayload();
        const action = 'Updating';
        console.log(`${action} user with payload:`);
        console.log(payload);
        setLoading(true);
        userUpdateMutation.mutate(payload, {
            onSuccess: ({ data }) => onUpdateSuccess(data),
            onError: onSubmitError,
        });
    };

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="md"
                PaperProps={{
                    component: 'form',
                    onSubmit: (e) => {
                        e.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle zIndex={3}>
                    {currentUser
                        ? `${currentUser.firstName} ${currentUser.lastName}`
                        : 'Update Account'}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        {ACCOUNT_FIELDS.filter((field) => {
                            return (
                                field.name === 'repType' ||
                                (field.modes.includes(mode) &&
                                    field.applicableForRepTypeIds.includes(formData.repType.value))
                            );
                        }).map((field) => {
                            switch (field.viewType) {
                                case VIEW_TYPES.TEXT:
                                    let value = formData[field.name].value;
                                    if (field.dataType === DATA_TYPES.TIMESTAMP) {
                                        value = value ? new Date(value).toLocaleString() : '';
                                    }
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyTextField
                                                value={value}
                                                editable={isRepFieldEditable(field)}
                                                label={field.label}
                                                type="text"
                                                onChange={(event) =>
                                                    handleInputChange(
                                                        field.name,
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.SINGLE_SELECT:
                                    let options = formData[field.name].options;
                                    if (options.length === 0) {
                                        if (field.dataType === DATA_TYPES.OBJECT) {
                                            options = [
                                                {
                                                    id: user[field.name]?.id ?? '',
                                                    name: user[field.name]?.name ?? '',
                                                },
                                            ];
                                        } else {
                                            options = [
                                                {
                                                    id: user[field.name] ?? '',
                                                    name: user[field.name] ?? '',
                                                },
                                            ];
                                        }
                                    }
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MySelect
                                                key={resetKey}
                                                label={field.label}
                                                options={options}
                                                value={formData[field.name].value}
                                                editable={isRepFieldEditable(field)}
                                                autoSelect={formData[field.name].value}
                                                onChange={(value) =>
                                                    handleInputChange(field.name, value)
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.MULTI_SELECT:
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyMultiSelectCheckboxes
                                                title={field.label}
                                                options={formData[field.name].options}
                                                value={formData[field.name].value}
                                                editable={isRepFieldEditable(field)}
                                                onChange={(value) =>
                                                    handleInputChange(field.name, value)
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.CHECKBOX:
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyCheckbox
                                                checked={!!formData[field.name].value}
                                                editable={isRepFieldEditable(field)}
                                                label={field.label}
                                                onChange={(event) =>
                                                    handleInputChange(
                                                        field.name,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        </Grid>
                                    );
                            }
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        m: 2,
                        zIndex: 3,
                    }}
                >
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" variant="contained" disabled={loading} loading={loading}>
                        UPDATE
                    </Button>
                </DialogActions>
                {errorMessages.repView && (
                    <Box mt={2}>
                        <Alert severity="error">{errorMessages.repView}</Alert>
                    </Box>
                )}
                {successMessages.repView && (
                    <Box mt={2}>
                        <Alert severity="success">{successMessages.repView}</Alert>
                    </Box>
                )}
            </Dialog>
        </>
    );
});

export default AccountModal;
