import axios from "axios";
import {API_DOMAIN} from "../constants";

export async function getRetailLocations(page, size, businessUnitCode, active, selectFitEbsCodeIds,
                                         selectFitCategoryIds, filterColumn, filterValue, filterOperator) {

    const includeInactive = active !== true;
    const inactiveOnly = active === false;

    const response = await axios({
        url: API_DOMAIN + "/api/v2/retailLocations",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        params: {
            page: page,
            size: size,
            businessUnitCode: businessUnitCode,
            includeInactive: includeInactive,
            inactiveOnly: inactiveOnly,
            selectFitEbsCodeIds: selectFitEbsCodeIds,
            selectFitCategoryIds: selectFitCategoryIds,
            filterColumn: filterColumn,
            filterValue: filterValue,
            filterOperator: filterOperator,
        },
    });

    return {retailLocations: response.data, totalCount: parseInt(response.headers['x-total-count'])};
}

export async function getRetailLocation(id) {

    const response = await axios({
        url: API_DOMAIN + `/api/v2/retailLocations/${id}`,
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
    });

    return response.data;
}

export async function updateRetailLocation(payload) {
    return axios({
        url: API_DOMAIN + "/api/v2/retailLocations",
        method: "PUT",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        data: payload,
    });
}

export async function createRetailLocation(payload) {
    return axios({
        url: API_DOMAIN + "/api/v2/retailLocations",
        method: "POST",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        data: payload,
    });
}

export async function getSelectFitEbsCodes() {

    const response = await axios({
        url: API_DOMAIN + "/api/v2/retailLocations/selectFitEbsCodes",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
    });

    return {selectFitEbsCodes: response.data};
}

export async function getSelectFitCategories() {

    const response = await axios({
        url: API_DOMAIN + "/api/v2/retailLocations/selectFitCategories",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
    });

    return {selectFitCategories: response.data};
}

export async function getSelectFitLogoTypes() {

    const response = await axios({
        url: API_DOMAIN + "/api/v2/retailLocations/selectFitLogoTypes",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
    });

    return {selectFitLogoTypes: response.data};
}
