import {
    AppBar, Box,
    Toolbar,
    Typography, useTheme,
} from '@mui/material';

const Topbar = ({title}) => {

    const theme = useTheme();

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: theme.palette.background,
            }}
        >
            <Typography variant="h1" component="div">
                {title}
            </Typography>
        </Box>
    );
};

export default Topbar;