import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const formatDate = (strDate, format = 'YYYY-MM-DD') => {
    if (strDate) {
        return dayjs(strDate).format(format);
    }
    return null;
};
export const fittingIsUpcoming = (scheduledStart) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const scheduledStartDay = dayjs.utc(scheduledStart);
    const nowLocal = dayjs();
    return scheduledStartDay.isAfter(nowLocal) || scheduledStartDay.isSame(nowLocal);
};
