import {Box, Button} from '@mui/material';
import {
    DataGrid,
    getGridNumericOperators,
    getGridStringOperators, GridFilterInputBoolean, GridFilterInputValue,
    GridFilterOperator,
    GridToolbar
} from '@mui/x-data-grid';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLocalStorage} from "../../hooks/useLocalStorage";
import PageHeader from "../../components/PageHeader";
import MyBooleanMark from "../../components/MyBooleanMark";
import {useEvents} from "../../hooks/useEvents";
import {useEventTypes} from "../../hooks/useEventTypes";
import {useBookabilityLevels} from "../../hooks/useBookabilityLevels";
import {useSpecsVisibilities} from "../../hooks/useSpecsVisibilities";
import {toPercentage} from "../../util/NumberUtil";
import {useNavigate} from "react-router-dom";
import {getCurrentUser} from "../../util/AuthUtil";
import routes from "../../routes";
import {REP_TYPES} from "../../constants";
import {Cancel, CheckCircle} from "@mui/icons-material";
import {useReps} from "../../hooks/useReps";
import {useRetailLocations} from "../../hooks/useRetailLocations";
import Grid from "@mui/material/Grid";
import RepsFilter from "../reps/RepsFilter";
import RetailLocationsFilter from "./RetailLocationsFilter";
import RepModal from "../reps/RepModal";
import RetailLocationModal from "./RetailLocationModal";

const FILTER_COLUMNS = {
    id: 'ID',
    shiptoId: 'SHIPTO_ID',
    displayName: 'DISPLAY_NAME',
    street: 'STREET',
    city: 'CITY',
    state: 'STATE',
    postalCode: 'POSTAL_CODE',
    countryCode: 'COUNTRY_CODE',
    onWebsite: 'ON_WEBSITE',
}

const RetailLocationsTable = () => {

    console.log("Rendering RetailLocationsTable");

    const [filter, setFilter] = useState({
        businessUnitCode: '',
        active: '',
        selectFitEbsCodeId: '',
        selectFitCategoryId: '',
        filterColumn: '',
        filterValue: '',
        filterOperator: '',
    });
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });

    const retailLocationModalRef = useRef();

    const navigate = useNavigate();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (!routes.find(route => route.key === 'retail-locations').visibleRepTypes.includes(currentUser.repType.id)) {
            navigate(REP_TYPES[currentUser.repType.id].defaultRoute);
        }
    }, [currentUser, navigate]);

    const [columnWidths, setColumnWidths] = useLocalStorage('retailLocationsTableColumnWidths', {});

    const gridNumericOperators = getGridNumericOperators();
    const gridStringOperators = getGridStringOperators();

    const textFilterOperators = [
        {
            label: "Contains",
            value: 'CONTAINS',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'text',
            },
        },
        {
            label: "Does not contain",
            value: 'DOES_NOT_CONTAIN',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'text',
            },
        },
        {
            label: "Equal to",
            value: 'EQUAL_TO',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'text',
            },
        },
        {
            label: "Not equal to",
            value: 'NOT_EQUAL_TO',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'text',
            },
        },
    ];

    const numberFilterOperators = [
        {
            label: "=",
            value: 'EQUAL_TO',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'number',
            },
        },
        {
            label: "!=",
            value: 'NOT_EQUAL_TO',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'number',
            },
        },
        {
            label: ">",
            value: 'GREATER_THAN',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'number',
            },
        },
        {
            label: ">=",
            value: 'GREATER_THAN_OR_EQUAL_TO',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'number',
            },
        },
        {
            label: "<",
            value: 'LESS_THAN',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'number',
            },
        },
        {
            label: "<=",
            value: 'LESS_THAN_OR_EQUAL_TO',
            InputComponent: GridFilterInputValue,
            InputComponentProps: {
                type: 'number',
            },
        },
    ];

    const booleanFilterOperators = [
        {
            label: "is",
            value: 'IS',
            InputComponent: GridFilterInputBoolean,
            InputComponentProps: {
                type: 'boolean',
            },
        },
    ];

    const columns = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: 'ID',
                width: columnWidths.id ?? 70,
                type: 'number',
                filterOperators: numberFilterOperators,
            },
            {
                field: 'businessUnitCode',
                headerName: 'BUSINESS UNIT',
                width: columnWidths.businessUnitCode ?? 130,
                type: 'string',
                filterable: false
            },
            {
                field: 'shiptoId',
                headerName: 'SHIPTO',
                width: columnWidths.shiptoId ?? 100,
                type: 'string',
                filterOperators: textFilterOperators,
            },
            {
                field: 'selectFitEbsCode',
                headerName: 'SELECT FIT EBS CODE',
                width: columnWidths.selectFitEbsCode ?? 250,
                filterable: false,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'NA';
                },
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.name.localeCompare(v2.name);
                },
            },
            {
                field: 'selectFitCategory',
                headerName: 'SELECT FIT CATEGORY',
                width: columnWidths.selectFitCategory ?? 250,
                filterable: false,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'NA';
                },
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.name.localeCompare(v2.name);
                },
            },
            {
                field: 'selectFitLogoType',
                headerName: 'SELECT FIT LOGO TYPE',
                width: columnWidths.selectFitLogoType ?? 250,
                filterable: false,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'NA';
                },
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.name.localeCompare(v2.name);
                },
            },
            {
                field: 'displayName',
                headerName: 'NAME',
                width: columnWidths.displayName ?? 100,
                filterOperators: textFilterOperators,
            },
            {
                field: 'street',
                headerName: 'STREET',
                width: columnWidths.street ?? 100,
                filterOperators: textFilterOperators,
            },
            {
                field: 'city',
                headerName: 'CITY',
                width: columnWidths.city ?? 100,
                filterOperators: textFilterOperators,
            },
            {
                field: 'state',
                headerName: 'STATE',
                width: columnWidths.state ?? 80,
                filterOperators: textFilterOperators,
            },
            {
                field: 'postalCode',
                headerName: 'POSTAL CODE',
                width: columnWidths.postalCode ?? 80,
                filterOperators: textFilterOperators,
            },
            {
                field: 'countryCode',
                headerName: 'COUNTRY',
                width: columnWidths.countryCode ?? 80,
                filterOperators: textFilterOperators,
            },
            {
                field: 'onWebsite',
                headerName: 'ON WEBSITE',
                width: columnWidths.onWebsite ?? 80,
                filterOperators: booleanFilterOperators,
                renderCell: (params) => (
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.value ? <CheckCircle color="success"/> : <Cancel color="error"/>}
                    </Box>
                ),
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1 - v2;
                },
            },
            {
                field: 'bookingLink',
                headerName: 'BOOKING LINK',
                width: columnWidths.bookingLink ?? 100,
                filterable: false,
            },
            {
                field: 'active',
                headerName: 'ACTIVE',
                width: columnWidths.active ?? 80,
                type: 'boolean',
                filterable: false,
                renderCell: (params) => (
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.value ? <CheckCircle color="success"/> : <Cancel color="error"/>}
                    </Box>
                ),
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1 - v2;
                },
            },
            {
                field: 'latitude',
                headerName: 'LATITUDE',
                width: columnWidths.latitude ?? 100,
                filterable: false,
            },
            {
                field: 'longitude',
                headerName: 'LONGITUDE',
                width: columnWidths.longitude ?? 100,
                filterable: false,
            },
        ];
    }, []);

    const [retailLocations, totalRowCount, refetchRetailLocations] = useRetailLocations(
        paginationModel.page,
        paginationModel.pageSize,
        filter.businessUnitCode,
        filter.active,
        filter.selectFitEbsCodeId,
        filter.selectFitCategoryId,
        filter.filterColumn,
        filter.filterValue,
        filter.filterOperator
    );

    const handleColumnResize = (params) => {
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };

    const filterChangeFunctions = {
        businessUnitCode: (value) => {
            setFilter(prevState => ({
                ...prevState,
                businessUnitCode: value,
            }));
        },
        active: (value) => {
            setFilter(prevState => ({
                ...prevState,
                active: value,
            }));
        },
        selectFitEbsCodeId: (value) => {
            setFilter(prevState => ({
                ...prevState,
                selectFitEbsCodeId: value,
            }));
        },
        selectFitCategoryId: (value) => {
            setFilter(prevState => ({
                ...prevState,
                selectFitCategoryId: value,
            }));
        },
    }

    function renderControlPanel() {
        return (
            <Box pt={1}>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        display: 'flex',
                        // justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Grid item xs={10}>
                        <RetailLocationsFilter
                            filter={filter}
                            filterChangeFunctions={filterChangeFunctions}
                            setFilter={setFilter}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        // alignItems: 'center',
                    }}>
                        <Button variant="contained" color="primary"
                                onClick={() => retailLocationModalRef.current.handleOpen()}>
                            + New Retail Location
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    function handleRowClick(params) {
        console.log(params);
        retailLocationModalRef.current.handleOpen(params.row.id);
    }

    // const handleFilterChange = useCallback((filterModel) => {
    //     console.log(filterModel);
    // }, []);
    function onFilterChange(filterModel) {
        console.log("FilterModel:");
        console.log(filterModel);
        const filterItem = filterModel.items[0];
        if (filterItem) {
            const filterColumn = FILTER_COLUMNS[filterItem.field];
            const filterValue = filterItem.value;
            const filterOperator = filterItem.operator;
            // if (filterColumn && filterValue && filterOperator) {
            setFilter(prevState => ({
                ...prevState,
                filterColumn: filterColumn,
                filterValue: filterValue,
                filterOperator: filterOperator,
            }));
            // }
        }
    }

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PageHeader title="Manage Retail Locations" children={renderControlPanel()}/>

                <Box sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}>
                    <DataGrid
                        columns={columns}
                        rows={retailLocations}
                        filterMode="server"
                        filterDebounceMs={500}
                        onFilterModelChange={onFilterChange}
                        paginationMode="server"
                        rowCount={totalRowCount}
                        pageSizeOptions={[10, 20, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{toolbar: GridToolbar}}
                        onRowClick={handleRowClick}
                        disableRowSelectionOnClick={true}
                        onColumnWidthChange={handleColumnResize}
                        // disableColumnFilter={true}
                        sx={{
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            // fontSize: '1rem',
                        }}
                    />
                </Box>
                <RetailLocationModal ref={retailLocationModalRef} refetchRetailLocations={refetchRetailLocations}/>
            </Box>
        </>
    );
};

export default RetailLocationsTable;
