import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';

import MySelect from '../../components/MySelect';
import { useManagedRepTypes } from '../../hooks/useManagedRepTypes';
import { useRepPermissions } from '../../hooks/useRepPermissions';
import MyTextField from '../../components/MyTextField';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useSalesRegions } from '../../hooks/useSalesRegions';
import { useUser } from '../../hooks/useUser';
import { DATA_TYPES, REP_FIELDS, VIEW_TYPES } from '../../constants';
import MyCheckbox from '../../components/MyCheckbox';
import { canLeftUserEditRightUser } from '../../util/UserUtil';
import MyMultiSelectCheckboxes from '../../components/MyMultiSelectCheckboxes';
import { useSalesTerritories } from '../../hooks/useSalesTerritories';
import { useUserUpdate } from '../../hooks/useUserUpdate';
import { useBusinessUnitCodes } from '../../hooks/useBusinessUnitCodes';
import { useUserCreate } from '../../hooks/useUserCreate';
import { extractFirstAndLastNameFromEmail, generatePassword } from '../../util/StringUtil';
import { useCountries } from '../../hooks/useCountries';
import { generateRandomNumber } from '../../util/NumberUtil';

const getInitialFormData = () => {
    const initialFormData = {};

    REP_FIELDS.forEach((field) => {
        initialFormData[field.name] = {};
        switch (field.viewType) {
            case VIEW_TYPES.TEXT:
                initialFormData[field.name].value = '';
                break;
            case VIEW_TYPES.SINGLE_SELECT:
                initialFormData[field.name].value = '';
                initialFormData[field.name].options = [];
                break;
            case VIEW_TYPES.MULTI_SELECT:
                initialFormData[field.name].value = [];
                initialFormData[field.name].options = [];
                break;
            case VIEW_TYPES.CHECKBOX:
                initialFormData[field.name].value = field.defaultValue;
                break;
        }
    });

    return initialFormData;
};

const RepModal = forwardRef(({ refetchReps }, ref) => {
    console.log('Rendering RepModal');

    const [resetKey, setResetKey] = useState(0);
    const [userId, setUserId] = useState(0);
    const [open, setOpen] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [formData, setFormData] = useState(getInitialFormData);
    const [mode, setMode] = useState('create');
    const [confirmationDialog, setConfirmationDialog] = useState({ open: false });

    console.log('resetKey: ' + resetKey);

    const overlayRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            handleOpen,
        }),
        []
    );

    const user = useUser(userId, open, (data) => {
        setFormData((prev) => {
            console.log('useUser callback');
            let newFormData = { ...prev };
            REP_FIELDS.forEach((field) => {
                switch (field.dataType) {
                    case DATA_TYPES.STRING:
                    case DATA_TYPES.TIMESTAMP:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name] ?? '',
                        };
                        break;
                    case DATA_TYPES.BOOLEAN:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: !!data[field.name],
                        };
                        break;
                    case DATA_TYPES.STRING_ARRAY:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name] ?? [],
                        };
                        break;
                    case DATA_TYPES.OBJECT:
                        newFormData[field.name] = {
                            ...prev[field.name],
                            value: data[field.name]?.id ?? '',
                        };
                        break;
                }
            });
            return newFormData;
        });
    });

    const [currentUser, updateCurrentUser] = useCurrentUser();

    const canBeEditedByCurrentUser = useMemo(() => {
        console.log('useMemo canBeEditedByCurrentUser');
        return canLeftUserEditRightUser(currentUser, user);
    }, [currentUser, user]);

    const isRepFieldEditable = (field) => {
        if (mode === 'create' && field.modes.includes('create')) {
            return true;
        }
        if (!canBeEditedByCurrentUser) {
            return false;
        }
        if (currentUser.id === user.id) {
            return field.selfEditable;
        }
        return field.editableByRepTypeIds.includes(currentUser.repType.id);
    };

    const formEditable = useMemo(() => {
        console.log('useMemo formEditable');
        let editable = false;
        for (let field of REP_FIELDS) {
            if (isRepFieldEditable(field)) {
                editable = true;
                break;
            }
        }
        return editable;
    }, [currentUser, user]);

    useManagedRepTypes(currentUser?.id, open, resetKey, (data) => {
        console.log('useManagedRepTypes callback');
        let newCurrentUser = { ...currentUser };
        newCurrentUser.managedRepTypeIds = data.map((repType) => repType.id);
        updateCurrentUser(newCurrentUser);
        // const repTypeOptions = data.map((name) => ({id: name, name: REP_TYPES[name] ?? name}));
        setFormData((prev) => {
            return { ...prev, repType: { ...prev.repType, options: data } };
        });
    });

    useBusinessUnitCodes(open && mode === 'create', resetKey, (data) => {
        console.log('useBusinessUnitCodes callback');
        setFormData((prev) => {
            return { ...prev, businessUnitCode: { ...prev.businessUnitCode, options: data } };
        });
    });

    useCountries(
        formData.businessUnitCode.value,
        open && isRepFieldEditable(REP_FIELDS.find((field) => field.name === 'countryCode')),
        (data) => {
            console.log('useCountries callback');
            setFormData((prev) => {
                if (mode === 'update') {
                    return { ...prev, countryCode: { ...prev.countryCode, options: data } };
                } else {
                    const defaultCountry = data ? data[0]?.id : '';
                    return {
                        ...prev,
                        countryCode: { ...prev.countryCode, options: data, value: defaultCountry },
                    };
                }
            });
        }
    );

    useSalesRegions(
        formData.businessUnitCode.value,
        currentUser.id,
        open && isRepFieldEditable(REP_FIELDS.find((field) => field.name === 'salesRegion')),
        (data) => {
            console.log('useSalesRegions callback');
            setFormData((prev) => {
                return { ...prev, salesRegion: { ...prev.salesRegion, options: data } };
            });
        }
    );

    useSalesTerritories(
        formData.businessUnitCode.value,
        formData.salesRegion.value,
        open && isRepFieldEditable(REP_FIELDS.find((field) => field.name === 'salesTerritories')),
        (data) => {
            console.log('useSalesTerritories callback');
            setFormData((prev) => {
                return { ...prev, salesTerritories: { ...prev.salesTerritories, options: data } };
            });
        }
    );

    useRepPermissions(
        formData.businessUnitCode.value,
        formData.repType.value,
        open && isRepFieldEditable(REP_FIELDS.find((field) => field.name === 'repPermission')),
        resetKey,
        (data) => {
            console.log('useRepPermissions callback');
            setFormData((prev) => {
                return { ...prev, repPermission: { ...prev.repPermission, options: data } };
            });
        }
    );

    const userUpdateMutation = useUserUpdate();
    const userCreateMutation = useUserCreate();

    const handleInputChange = (key, value) => {
        setFormData((prev) => {
            let newFormData = { ...prev, [key]: { ...prev[key], value: value } };
            if (key === 'email') {
                if (prev.login.value === prev.email.value) {
                    newFormData.login.value = value;
                }
                let prevDerivedNames = extractFirstAndLastNameFromEmail(prev.email.value);
                let newDerivedNames = extractFirstAndLastNameFromEmail(value);
                if (prevDerivedNames.firstName === prev.firstName.value) {
                    newFormData.firstName.value = newDerivedNames.firstName;
                }
                if (prevDerivedNames.lastName === prev.lastName.value) {
                    newFormData.lastName.value = newDerivedNames.lastName;
                }
            }
            return newFormData;
        });
    };

    const handleOpen = (userId) => {
        if (!!userId) {
            setUserId(userId);
            setMode('update');
        } else {
            setUserId(0);
            setMode('create');
            setFormData((prev) => {
                return { ...prev, orderPin: { value: generateRandomNumber(4) } };
            });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setErrorMessages({});
        setOpen(false);
        setUserId(0);
        // setFormData(getInitialFormData());
        setFormData((prev) => {
            let newFormData = { ...prev };
            REP_FIELDS.forEach((field) => {
                switch (field.viewType) {
                    case VIEW_TYPES.TEXT:
                        newFormData[field.name].value = '';
                        break;
                    case VIEW_TYPES.SINGLE_SELECT:
                        newFormData[field.name].value = '';
                        newFormData[field.name].options = [];
                        break;
                    case VIEW_TYPES.MULTI_SELECT:
                        newFormData[field.name].value = [];
                        newFormData[field.name].options = [];
                        break;
                    case VIEW_TYPES.CHECKBOX:
                        newFormData[field.name].value = field.defaultValue;
                        break;
                }
            });
            return newFormData;
        });
        setResetKey((prev) => prev + 1);
    };

    const composePayload = () => {
        let payload = {};
        REP_FIELDS.forEach((field) => {
            if (field.key && isRepFieldEditable(field)) {
                switch (field.dataType) {
                    case DATA_TYPES.BOOLEAN:
                        payload[field.key] = !!formData[field.name].value;
                        break;
                    case DATA_TYPES.STRING_ARRAY:
                        let filteredValue = formData[field.name].value.filter((id) =>
                            formData[field.name].options.some((option) => option.id === id)
                        );
                        if (filteredValue.length > 0) {
                            payload[field.key] = filteredValue;
                        }
                        break;
                    default:
                        if (!!formData[field.name].value) {
                            // if (field.name === 'repType') {
                            //     payload[field.key] = translateRepTypeToAuthorities(formData[field.name].value);
                            // } else {
                            payload[field.key] = formData[field.name].value;
                            // }
                        }
                        break;
                }
            }
        });
        if (userId) {
            payload.id = userId;
        }
        payload.authorities = ['ROLE_EXP_REP'];

        return payload;
    };

    const onCreateSuccess = (response, payload) => {
        refetchReps();
        handleClose();
        setConfirmationDialog({
            open: true,
            emailSent: response.data.repCreatedEmailSent,
            email: response.data.email,
            payload,
        });
    };

    const onUpdateSuccess = () => {
        refetchReps();
        handleClose();
    };

    const onSubmitError = (error) => {
        setErrorMessages({ repView: error.response.data.message });
    };

    const handleSubmit = () => {
        // handleClose();
        const payload = composePayload();
        const action = mode === 'create' ? 'Creating' : 'Updating';
        console.log(`${action} user with payload:`);
        console.log(payload);

        if (mode === 'create') {
            payload['password'] = generatePassword(10);
            userCreateMutation.mutate(payload, {
                onSuccess: (response) => onCreateSuccess(response, payload),
                onError: onSubmitError,
            });
        } else {
            userUpdateMutation.mutate(payload, {
                onSuccess: onUpdateSuccess,
                onError: onSubmitError,
            });
        }
    };

    const calculateOverlayTextAngle = (element) => {
        if (element) {
            const { width, height } = element.getBoundingClientRect();
            const radianAngle = Math.atan2(height, width);
            return -radianAngle * (180 / Math.PI);
        }
        return 0;
    };

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="md"
                PaperProps={{
                    component: 'form',
                    onSubmit: (e) => {
                        e.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle zIndex={3}>
                    {userId && user ? `${user.firstName || ''} ${user.lastName || ''}` : 'New User'}
                    {mode === 'update' &&
                        isRepFieldEditable(REP_FIELDS.find((item) => item.key === 'activated')) && (
                            <Button
                                sx={{
                                    float: 'right',
                                    textDecoration: 'underline',
                                }}
                                onClick={() => {
                                    setFormData((prev) => {
                                        return {
                                            ...prev,
                                            activated: {
                                                ...prev.activated,
                                                value: !prev.activated.value,
                                            },
                                        };
                                    });
                                }}
                            >
                                {formData.activated.value ? 'Deactivate' : 'Re-activate'}
                            </Button>
                        )}
                </DialogTitle>
                <DialogContent ref={overlayRef} dividers={true}>
                    {!formData.activated.value && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backdropFilter: 'blur(3px)',
                                zIndex: 2,
                            }}
                        >
                            <Typography
                                variant="h1"
                                letterSpacing={10}
                                fontWeight={700}
                                textTransform={'uppercase'}
                                sx={{
                                    transform: `rotate(${calculateOverlayTextAngle(overlayRef.current)}deg)`,
                                }}
                            >
                                Inactive
                            </Typography>
                        </Box>
                    )}
                    <Grid container spacing={1}>
                        {REP_FIELDS.filter((field) => {
                            return (
                                field.name === 'repType' ||
                                (field.modes.includes(mode) &&
                                    field.applicableForRepTypeIds.includes(formData.repType.value))
                            );
                        }).map((field) => {
                            switch (field.viewType) {
                                case VIEW_TYPES.TEXT:
                                    let value = formData[field.name].value;
                                    if (field.dataType === DATA_TYPES.TIMESTAMP) {
                                        value = value ? new Date(value).toLocaleString() : '';
                                    }
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyTextField
                                                value={value}
                                                // editable={formData[field.name].editable}
                                                editable={isRepFieldEditable(field)}
                                                label={field.label}
                                                type="text"
                                                required={
                                                    mode === 'create' && field.requiredForCreate
                                                }
                                                onChange={(event) =>
                                                    handleInputChange(
                                                        field.name,
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.SINGLE_SELECT:
                                    let options = formData[field.name].options;
                                    if (options.length === 0) {
                                        if (field.dataType === DATA_TYPES.OBJECT) {
                                            options = [
                                                {
                                                    // id: formData[field.name].value.id ?? '',
                                                    // name: formData[field.name].value.name ?? ''
                                                    id: user[field.name]?.id ?? '',
                                                    name: user[field.name]?.name ?? '',
                                                },
                                            ];
                                        } else {
                                            options = [
                                                {
                                                    // id: formData[field.name].value,
                                                    // name: formData[field.name].value
                                                    id: user[field.name] ?? '',
                                                    name: user[field.name] ?? '',
                                                },
                                            ];
                                        }
                                    }
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MySelect
                                                key={resetKey}
                                                label={field.label}
                                                options={options}
                                                // value={field.dataType === DATA_TYPES.OBJECT ? formData[field.name].value.id : formData[field.name].value}
                                                value={formData[field.name].value}
                                                editable={isRepFieldEditable(field)}
                                                required={
                                                    mode === 'create' && field.requiredForCreate
                                                }
                                                autoSelect={
                                                    formData[field.name].value ||
                                                    (mode === 'create' && field.name !== 'repType')
                                                }
                                                onChange={(value) =>
                                                    handleInputChange(field.name, value)
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.MULTI_SELECT:
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyMultiSelectCheckboxes
                                                title={field.label}
                                                options={formData[field.name].options}
                                                value={formData[field.name].value}
                                                editable={isRepFieldEditable(field)}
                                                onChange={(value) =>
                                                    handleInputChange(field.name, value)
                                                }
                                            />
                                        </Grid>
                                    );
                                case VIEW_TYPES.CHECKBOX:
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <MyCheckbox
                                                checked={!!formData[field.name].value}
                                                editable={isRepFieldEditable(field)}
                                                label={field.label}
                                                onChange={(event) =>
                                                    handleInputChange(
                                                        field.name,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        </Grid>
                                    );
                            }
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        m: 2,
                        zIndex: 3,
                    }}
                >
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" variant="contained" autoFocus disabled={!formEditable}>
                        {mode === 'update' ? 'UPDATE' : 'CREATE'}
                    </Button>
                </DialogActions>
                {errorMessages.repView && (
                    <Box mt={2}>
                        <Alert severity="error">{errorMessages.repView}</Alert>
                    </Box>
                )}
            </Dialog>
            <Dialog open={confirmationDialog.open}>
                <DialogTitle>{confirmationDialog.emailSent ? 'Success' : 'Warning'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmationDialog.emailSent
                            ? 'A welcome email has been sent to ' + confirmationDialog.email
                            : 'Failed to send welcome email to ' + confirmationDialog.email}
                    </DialogContentText>
                    {confirmationDialog?.payload && confirmationDialog?.payload?.password && (
                        <DialogContentText>
                            {'Current Password: ' + confirmationDialog?.payload?.password}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmationDialog((prev) => ({ ...prev, open: false }))}
                        color="primary"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default RepModal;
